/********************************
* Typekit
********************************/

/*
* Fonts for the maintenance layout
*/
@import "./fonts/maintenance-layout-fonts.css";

/*
* proxima-nova
*   normal 100
*   italic 100
*   normal 600
*   italic 600
*   normal 400
*   italic 400
*   normal 300
*   italic 300
*   italic 500
*
* adobe-caslon-pro:
*   normal 400
*   italic 400
*/

@import "./fonts/SainteColombe/saintecolombe.css";

/********************************
* Monotype
*********************************/

/*
* Caslon #540 Roman
*/
@font-face {
  font-family: "caslon-540-roman";
  font-display: swap;
  src: url("./fonts/Caslon/roman/Caslon540LTW05-Roman.woff2") format("woff2"),
    url("./fonts/Caslon/roman/Caslon540LTW05-Roman.woff") format("woff"),
    url("./fonts/Caslon/roman/Caslon540LTW05-Roman.ttf") format("truetype");
}

/*
* Caslon #540 Italic
*/
@font-face {
  font-family: "caslon-540-italic";
  font-display: swap;
  src: url("./fonts/Caslon/italic/Caslon540LTW05-Italic.woff2") format("woff2"),
    url("./fonts/Caslon/italic/Caslon540LTW05-Italic.woff") format("woff"),
    url("./fonts/Caslon/italic/Caslon540LTW05-Italic.ttf") format("truetype");
}

/********************************
* Beatrice
*********************************/

/*
* Beatrice
*/

@font-face {
  font-family: "Beatrice";
  font-display: swap;
  font-weight: normal;
  src: url("./fonts/Beatrice/Beatrice-Regular.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Regular.woff") format("woff");
}
@font-face {
  font-family: "Beatrice";
  font-display: swap;
  font-weight: bold;
  src: url("./fonts/Beatrice/Beatrice-Medium.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Medium.woff") format("woff");
}
@font-face {
  font-family: "Beatrice";
  font-display: swap;
  font-weight: lighter;
  src: url("./fonts/Beatrice/Beatrice-Light.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Light.woff") format("woff");
}

@font-face {
  font-family: "BeatriceRegular";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-Regular.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Regular.woff") format("woff");
}
@font-face {
  font-family: "BeatriceMedium";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-Medium.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Medium.woff") format("woff");
}

@font-face {
  font-family: "BeatriceLight";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-Light.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Light.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Thin";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-Thin.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Thin.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Semibold";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-Semibold.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Semibold.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Bold";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-Bold.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Bold.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Extra Bold";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-Extrabold.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-Extrabold.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Italic Thin";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-ThinItalic.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Italic Light";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-LightItalic.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Italic Regular";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-RegularItalic.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-RegularItalic.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Italic Medium";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-MediumItalic.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Italic Semibold";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-SemiboldItalic.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-SemiboldItalic.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Italic Bold";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-BoldItalic.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Beatrice Italic Extra Bold";
  font-display: swap;
  src: url("./fonts/Beatrice/Beatrice-ExtraboldItalic.woff2") format("woff2"),
    url("./fonts/Beatrice/Beatrice-ExtraboldItalic.woff") format("woff");
}

/********************************
* PP Fragment
*********************************/

/*
* PP Fragment
*/

@font-face {
  font-family: "PP Fragment";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifRegular.woff2") format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifRegular.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Thin";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifThin.woff2") format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifThin.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Extra Light";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifExtraLight.woff2")
      format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifExtraLight.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Light";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifLight.woff2") format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifLight.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Medium";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifMedium.woff2") format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifMedium.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Semibold";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifSemiBold.woff2") format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifSemiBold.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Bold";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifBold.woff2") format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifBold.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Extrabold";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifExtraBold.woff2") format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifExtraBold.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Black";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifBlack.woff2") format("woff2"),
    url("./fonts/PPFragment/PPFragment-SerifBlack.woff") format("woff");
}

@font-face {
  font-family: "PP Fragment Italic Thin";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifThinItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareThinItalic.otf") format("opentype");
}

@font-face {
  font-family: "PP Fragment Italic Extra Light";
  src: url("./fonts/PPFragment/PPFragment-SerifExtraLightItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareExtraLightItalic.otf")
      format("opentype");
}

@font-face {
  font-family: "PP Fragment Italic Light";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifLightItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareLightItalic.otf") format("opentype");
}

@font-face {
  font-family: "PP Fragment Italic Regular";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifRegularItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareLightItalic.otf") format("opentype");
}

@font-face {
  font-family: "PP Fragment Italic Medium";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifMediumItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareMediumItalic.otf")
      format("opentype");
}

@font-face {
  font-family: "PP Fragment Italic Semibold";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifSemiBoldItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareSemiBoldItalic.otf")
      format("opentype");
}

@font-face {
  font-family: "PP Fragment Italic Bold";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifBoldItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareBoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "PP Fragment Italic Extrabold";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifExtraBoldItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareExtraBoldItalic.otf")
      format("opentype");
}

@font-face {
  font-family: "PP Fragment Italic Black";
  font-display: swap;
  src: url("./fonts/PPFragment/PPFragment-SerifBlackItalic.otf")
      format("opentype"),
    url("./fonts/PPFragment/PPFragment-GlareBlackItalic.otf") format("opentype");
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  scroll-padding-top: 80px;
}

body:not(.accessible-mode-enabled) *:focus {
  outline: none !important;
}

body:not(.accessible-mode-enabled) input:focus,
select:focus,
textarea:focus {
  outline: 1px solid #383838;
}

/* disable scrolling on body when GlobalPopup is open */
.ReactModal__Body--open {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overscroll-behavior: none;
  position: relative !important;
  overflow: hidden !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.ck-body-wrapper {
  position: absolute;
  z-index: 10000;
}

body.overflow-hide {
  overflow: hidden !important;
}

/* CSS to get around safari mobile bug with ctas at bottom of viewport */
body.side-panel-active {
  @media screen and (max-width: 480px) {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
  }

  @media screen and (max-height: 480px) and (orientation: landscape) {
    height: 100%;
    position: fixed;
    width: 100%;
    top: 0;
  }
}

/* CSS to get around safari mobile bug with ctas at bottom of viewport */
.hide-small-mobile {
  display: none !important;

  @media screen and (min-width: 480px) {
    display: flex !important;
  }

  @media screen and (max-height: 480px) and (orientation: landscape) {
    display: none !important;
  }
}

/* overides react-quick-pinch-zoom scroll behavior*/
.kvfysmfp {
  touch-action: manipulation !important;
  @media screen and (min-width: 668px) {
    touch-action: none !important;
  }
}

#monkey-survey-overlay {
  opacity: 0.8;
  filter: alpha(opacity=80);
  zoom: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: #000;
}

.modalWindow .modalWindowContent {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.modalWindow .modalWindowHeader {
  font-size: 4em;
  margin-top: -20px;
}

.modalWindow .modalWindowClose {
  text-align: right;
  cursor: pointer;
  font-size: 15px;
}

.modalWindow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
  text-align: center;
}

.modalWindowTarget {
  opacity: 1;
  pointer-events: auto;
}
.modalWindow > div {
  width: 500px;
  position: relative;
  margin: 10% auto;
  background: #fff;
}

.modalWindow #px-captcha {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modalWindow #px-captcha .g-recaptcha {
  display: inline-block;
}

.modalWindow .reasons {
  margin-top: 20px;
  margin-bottom: 15px;
}

.modalWindow .reasons span {
  color: #c0c0c0;
  font-size: 13px;
}

.modalWindow .pxRefId {
  font-size: 13px;
}
.px-logo {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1024px) and (orientation: landscape) {
  .modalWindow > div {
    width: 600px;
    margin: 1% auto;
  }
  .px-logo {
    max-width: 75%;
  }
  .modalWindow #px-captcha {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .modalWindow > div {
    width: 90%;
    margin: 5% auto;
  }

  .modalWindow .modalWindowContent {
    padding: 10px;
  }

  .modalWindow .modalWindowHeader {
    font-size: 2em;
    margin-top: -10px;
  }

  .modalWindow .modalWindowClose {
    font-size: 12px;
  }

  .modalWindow .reasons span {
    font-size: 11px;
  }

  .modalWindow .pxRefId {
    font-size: 11px;
  }
}

.osano-cm-widget {
  display: none;
}

.osano-cm-drawer-item.osano-cm-list__list-item.osano-cm-list-item:last-child {
  display: none;
}

.osano-cm-drawer-item.osano-cm-list-item__disclosure.osano-cm-disclosure.osano-cm-disclosure--collapse {
  padding: 0;
}

.osano-cm-disclosure__toggle.osano-cm-toggle {
  display: none;
}

.rc-slider-disabled {
  background-color: transparent !important;
}
.pac-container {
  z-index: 999999 !important;
}
